<template>
  <div class="selectLinePopup">
    <!-- 选线框 -->
    <van-overlay :show="showOverlay" z-index="999" class="selectLineOverlay">
      <div class="selectionLineBox">
        <div class="titleBox">选择线路</div>
        <div class="lineArea">
          <div
            class="lineBox"
            @click.stop="changeLine(item)"
            v-for="(item, index) in apiUrls"
            :key="item"
          >
            <div class="selected" v-if="item == currentApi">
              <div class="select"></div>
            </div>
            <div class="unselect" v-else></div>
            <div class="line">线路{{ index + 1 }}</div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
export default {
  props: ["apiUrls", "showOverlay"],
  data() {
    return {
      currentApi: "", // 当前api
    };
  },
  created() {
    this.currentApi = this.apiUrls[0];
  },
  methods: {
    changeLine(item) {
      this.currentApi = item;
      this.$emit("selectLine", item);
    },
  },
};
</script>
<style scoped lang="scss">
// 选线
.selectLineOverlay {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .selectionLineBox {
    background-color: white;
    border-radius: 0.21rem;
    overflow: hidden;
    width: 7.466rem;
    .titleBox {
      color: white;
      font-size: 0.4rem;
      text-align: center;
      height: 1.0133rem;
      line-height: 1.0133rem;
      background: linear-gradient(
        142deg,
        #ffae61 0%,
        #ff1101 48%,
        #ff00b9 100%
      );
    }
    .lineArea {
      padding: 0.64rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .lineBox {
        white-space: nowrap;
        margin: 0.213rem 0.533rem 0.213rem 0.533rem;
        display: flex;
        align-items: center;
        .unselect {
          height: 0.32rem;
          width: 0.32rem;
          border-radius: 50%;
          background-color: rgba(238, 238, 238, 1);
        }
        .selected {
          height: 0.373rem;
          width: 0.373rem;
          border-radius: 50%;
          background-color: rgba(238, 238, 238, 1);
          display: flex;
          align-items: center;
          justify-content: center;
          .select {
            height: 0.373rem;
            width: 0.373rem;
            border-radius: 50%;
            background-color: rgba(221, 0, 27, 1);
          }
        }
        .line {
          font-size: 0.4rem;
          color: #666;
          margin-left: 0.106rem;
          flex: 1;
        }
      }
    }
  }
}
</style>
